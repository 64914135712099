@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    @apply bg-[url('./materia-background.jpg')] bg-no-repeat bg-cover;
}

.animate-arrow {
    animation-name: animate-arrow;
    animation-duration: 0.5s;
    display: inline-flex;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    animation-fill-mode: backwards;
    animation-direction: alternate;
}

@keyframes animate-arrow {
    0% {
        opacity: 0.5;
        translate: translateX(0);
    }
    100% {
        opacity: 1;
        transform: translateX(10px);
    }
}
